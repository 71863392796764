define('bottomline-execution/routes/trips/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),

    model: function model() {
      return this.modelFor('trips').get('trips');
    },
    setupController: function setupController(controller, model) {
      var siteStoragesLoader = this.controllerFor('application').get('siteStoragesLoader');
      siteStoragesLoader.then(function () {
        controller.set('isReportDataLoaded', true);
      });

      var tripsToShow = model.filter(function (trip) {
        return trip.get('started');
      });
      tripsToShow.forEach(function (trip) {
        return trip.calculateEndInventoryAndCorrection();
      });

      tripsToShow.forEach(function (trip) {
        return trip.set('isShown', true);
      });

      this._super(controller, model);
    }
  });
});
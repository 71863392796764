define('bottomline-execution/models/state', ['exports', 'ember-data', 'bottomline-execution/config/environment', 'bottomline-execution/mixins/truck-settings-id'], function (exports, _emberData, _environment, _truckSettingsId) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;


  var defaultScanner = 'hp';

  exports.default = Model.extend(_truckSettingsId.default, {
    onReady: Ember.on('ready', function () {
      var state = this.store.peekRecord('state', 1);
      if (Ember.isEmpty(state.get('scanner_brand'))) {
        // Set default value in this hook to prevent application store cache data to hold scanner_brand set to null
        // Using `scanner_brand: attr('string', { defaultValue: ... })` will not update value in application store cache`
        state.set('scanner_brand', defaultScanner);
      }
    }),

    selectedTrip: attr('string'),
    selectedShift: attr('string'),
    startedShift: attr('string'),
    selectedTripStop: attr('string'),
    scanner_ssid: attr('string'),
    scanner_password: attr('string'),
    scanner_brand: attr('string'),
    currentTruck: attr('string'),
    currentTrailer: attr('string'),
    currentCompoundVehicle: Ember.computed.alias('shift.CompoundVehicleID'),
    language: attr('string'),
    domain: attr('string'),
    alias: attr('string'),
    android_logging: attr('boolean', { defaultValue: false }),
    application_logging: attr('boolean', { defaultValue: true }),
    backend_type: attr('string', { defaultValue: 'be' }),
    auth0: attr(),
    toggleShowMaterialCalculation: attr('boolean'),
    toggleShowProblemAtgSites: attr('boolean'),
    toggleShowMap: attr('boolean'),
    toggleShowDetails: attr('boolean'),
    toggleShowRemarks: attr('boolean'),
    toggleShowMaterialOnTruck: attr('boolean'),
    toggleShowSiteLoadData: attr('boolean'),
    toggleShowActions: attr('boolean', { defaultValue: true }),
    anyShiftStarted: attr('number', { defaultValue: false }),

    getTruck: function getTruck() {
      var truckId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get('currentTruck');

      if (Ember.isBlank(truckId)) return null;
      return this.store.peekRecord('truck', truckId);
    },
    getTrailer: function getTrailer() {
      var trailerId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get('currentTrailer');

      if (Ember.isBlank(trailerId)) return null;
      return this.store.peekRecord('trailer', trailerId);
    },
    setSelectedShift: function setSelectedShift(shiftId) {
      this.set('selectedShift', shiftId);
      this._propagateShiftSelection();
    },
    setSelectedTrip: function setSelectedTrip(tripId) {
      this.set('selectedTrip', tripId);
      this._updateSelectedShift();
      this._updateSelectedTripStop();
    },
    setSelectedTripStop: function setSelectedTripStop(tripStopId) {
      this.set('selectedTripStop', tripStopId);
      this._propagateTripStopSelection();
    },
    _propagateShiftSelection: function _propagateShiftSelection() {
      var currentSelectedTripId = this.get('selectedTrip');
      var tripIdsInSelectedShift = this.get('shift.sortedTrips').map(function (t) {
        return t.get('id');
      });

      if (!tripIdsInSelectedShift.includes(currentSelectedTripId)) {
        var tripId = tripIdsInSelectedShift.get('firstObject');
        this.set('selectedTrip', tripId);
        this._updateSelectedTripStop();
      }
    },
    _propagateTripStopSelection: function _propagateTripStopSelection() {
      var tripId = this.get('tripStop.trip.id');
      this.set('selectedTrip', tripId);
      this._updateSelectedShift();
    },
    _updateSelectedShift: function _updateSelectedShift() {
      var shiftId = this.get('trip.shift.id');
      if (shiftId) {
        this.set('selectedShift', shiftId);
      }
    },
    _updateSelectedTripStop: function _updateSelectedTripStop() {
      var currentSelectedTripStopId = this.get('selectedTripStop');
      var tripStopsInSelectedTrip = this.get('trip.sortedDisplayableTripStops') || [];
      var tripStopIdsInSelectedTrip = tripStopsInSelectedTrip.map(function (t) {
        return t.get('id');
      });

      if (!tripStopIdsInSelectedTrip.includes(currentSelectedTripStopId)) {
        var tripStopId = tripStopIdsInSelectedTrip.get('firstObject');
        this.set('selectedTripStop', tripStopId);
      }
    },


    shift: Ember.computed('selectedShift', function () {
      if (Ember.isEmpty(this.get('selectedShift'))) return;
      return this.store.peekRecord('shift', this.get('selectedShift'));
    }).volatile(),

    trip: Ember.computed('selectedTrip', function () {
      if (Ember.isEmpty(this.get('selectedTrip'))) return;
      return this.store.peekRecord('trip', this.get('selectedTrip'));
    }).volatile(),

    tripStop: Ember.computed('selectedTripStop', function () {
      if (Ember.isEmpty(this.get('selectedTripStop'))) return;
      return this.store.peekRecord('trip-stop', this.get('selectedTripStop'));
    }),

    apiPath: Ember.computed('useBeBackend', function () {
      if (this.get('useBeBackend')) {
        return _environment.default.api;
      } else {
        return 'request';
      }
    }),

    useBxBackend: Ember.computed.equal('backend_type', 'bx'),
    useBeBackend: Ember.computed.not('useBxBackend'),

    truckSettings: Ember.computed('currentTruck', function () {
      var settingsId = this.truckSettingsId(this.get('currentTruck'));
      var settings = void 0;
      settings = this.get('store').peekRecord('truck-setting', settingsId);
      if (settings) {
        return settings;
      }

      settings = this.get('store').createRecord('truck-setting', { id: settingsId });
      return settings;
    })
  });
});
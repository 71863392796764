define('bottomline-execution/services/material-based/current-truck-inventory', ['exports', 'bottomline-execution/services/current-truck-inventory'], function (exports, _currentTruckInventory) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _currentTruckInventory.default.extend({
    quantityForMaterial: function quantityForMaterial(materialId) {
      return this.materialInventoryFor(materialId).get('currentQty');
    },
    forVehicle: function forVehicle(vehicleIds) {
      return this.materialInventories(vehicleIds);
    },
    _initialQuantityForMaterialInventory: function _initialQuantityForMaterialInventory(_materialId) {
      return 0;
    }
  });
});
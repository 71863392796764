define('bottomline-execution/routes/trip-stops/new/from-site', ['exports', 'bottomline-execution/mixins/trip-stop-material-on-truck'], function (exports, _tripStopMaterialOnTruck) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_tripStopMaterialOnTruck.default, {
    setupController: function setupController(controller, model) {
      controller.set('materialsOnTruck', this.tripStopMaterialOnTruck());

      model = [];
      model.isLoaded = true;
      controller.set('model', model);
    }
  });
});
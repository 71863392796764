define('bottomline-execution/components/truck-inventory', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),
    store: Ember.inject.service(),
    vehicleStorages: Ember.inject.service(),
    currentTruckInventory: Ember.inject.service(),

    truckInventoriesSorted: ['truck_storage_id'],
    trailerInventoriesSorted: ['truck_storage_id'],
    sortedTruckInventory: Ember.computed.sort('truckInventories', 'truckInventoriesSorted'),
    sortedTrailerInventory: Ember.computed.sort('trailerInventories', 'trailerInventoriesSorted'),

    hasTruckStorages: Ember.computed('appState.currentTruck', function () {
      return this.get('vehicleStorages').hasTruckStorages();
    }),

    hasTrailerStorages: Ember.computed('appState.currentTrailer', function () {
      return this.get('vehicleStorages').hasTrailerStorages();
    }),
    truckInventories: Ember.computed('appState.currentTruck', function () {
      var truckId = this.get('appState.currentTruck');
      return this.get('currentTruckInventory').forVehicle(truckId);
    }),
    trailerInventories: Ember.computed('appState.currentTrailer', function () {
      var trailerId = this.get('appState.currentTrailer');
      return this.get('currentTruckInventory').forVehicle(trailerId);
    })
  });
});
define('bottomline-execution/mixins/truck-settings-id', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    truckSettingsId: function truckSettingsId(truckId) {
      return truckId + 'settings';
    }
  });
});
define('bottomline-execution/components/end-inventory/trip-stop-storage-line', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['trip-stop-storage'],
    classNameBindings: ['isSelected'],
    tagName: 'tr',

    activeTripStopStorageId: null,

    isSelected: Ember.computed('activeTripStopStorageId', function () {
      return this.get('activeTripStopStorageId') === this.get('tripStopStorage.id');
    }),

    didInsertElement: function didInsertElement() {
      if (Ember.isPresent(this.get('tripStopStorage'))) {
        this.get('tripStopStorage').calculateTotalCompartmentQty();
      }
    },


    actions: {
      emptyTripStopTruckStorages: function emptyTripStopTruckStorages(tripStopStorage) {
        tripStopStorage.set('confirmedQty', 0);
        tripStopStorage.get('trip_stop.trip_stop_truck_storages').filter(function (tsts) {
          return tsts.get('MaterialID') === tripStopStorage.get('MaterialID');
        }).setEach('confirmedQty', 0);
      }
    }
  });
});
define('bottomline-execution/services/current-truck-inventory', ['exports', 'bottomline-execution/utils/guid-util'], function (exports, _guidUtil) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    truckId: Ember.computed.alias('appState.currentTruck'),
    trailerId: Ember.computed.alias('appState.currentTrailer'),

    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),

    truck: Ember.computed('truckId', function () {
      return this.store.peekRecord('truck', this.get('truckId'));
    }),

    inventories: Ember.computed.alias('appState.shift.truck_inventories'),

    allInventoriesForVehicle: function allInventoriesForVehicle(vehicleIds) {
      vehicleIds = [].concat(vehicleIds);
      return this.get('inventories').filter(function (inventory) {
        return vehicleIds.includes(inventory.get('truck_id'));
      });
    },
    availableMaterials: function availableMaterials() {
      var inventoriesWithMaterial = this.get('inventories').filter(function (inventory) {
        return inventory.get('currentQty') > 0;
      });
      return this.materialIds(inventoriesWithMaterial);
    },
    materialIds: function materialIds() {
      var inventories = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get('inventories');

      return inventories.getEach('material_id').compact().uniq();
    },
    materialInventories: function materialInventories() {
      var vehicleIds = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [this.get('truckId'), this.get('trailerId')];

      return this.allInventoriesForVehicle(vehicleIds).filter(function (inventory) {
        return Number(inventory.get('truck_storage_id')) == 0;
      });
    },


    materialInventoryFor: Ember.computed.alias('findOrCreateMaterialInventoryFor'),

    findOrCreateMaterialInventoryFor: function findOrCreateMaterialInventoryFor(materialId) {
      var materialInventory = this.materialInventories().findBy('material_id', materialId);

      if (Ember.isEmpty(materialInventory)) {
        materialInventory = this.get('inventories').createRecord({
          id: (0, _guidUtil.guidUtil)(),
          currentQty: this._initialQuantityForMaterialInventory(materialId),
          material_id: materialId,
          truck_id: this.get('truckId'),
          truck_storage_id: 0
        });
      }

      return materialInventory;
    },
    confirm: function confirm() {
      this.get('inventories').invoke('save');
    },
    rollbackAttributes: function rollbackAttributes() {
      this.get('inventories').invoke('rollbackAttributes');
    }
  });
});
define('bottomline-execution/mixins/trip-stop-material-on-truck', ['exports', 'bottomline-execution/mixins/group-by'], function (exports, _groupBy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_groupBy.default, {
    store: Ember.inject.service(),
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),
    currentTruckInventory: Ember.inject.service(),

    tripStopMaterialOnTruck: function tripStopMaterialOnTruck() {
      var _this = this;

      var state = this.get('appState');
      var vehicleIds = [state.get('currentTruck'), state.get('currentTrailer')];

      var targetTruckInventories = this.get('currentTruckInventory').forVehicle(vehicleIds);

      var groupedTargetTruckInventories = this.groupBy(targetTruckInventories, 'material_id', ['currentQty']).map(function (item) {
        var materialId = item.group;
        item.set('material', _this.get('store').peekRecord('material', materialId));
        return item;
      });

      return groupedTargetTruckInventories.filterBy('totalcurrentQty');
    }
  });
});
define('bottomline-execution/models/truck-storage', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    currentTruckInventory: Ember.inject.service(),
    maxTruckStorageQty: attr('string'),
    minTruckStorageQty: attr('string'),
    TruckStorageID: attr('number'),
    truck_id: attr('string'),
    vehicle_type: attr('string'),

    truckInventory: Ember.computed('TruckStorageID', function () {
      var _this = this;

      return this.get('currentTruckInventory').forVehicle(this.get('truck_id')).find(function (truckInventory) {
        return Number(truckInventory.get('truck_storage_id')) === Number(_this.get('TruckStorageID'));
      });
    })
  });
});
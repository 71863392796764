define('bottomline-execution/models/trip-stop-storage', ['exports', 'ember-data', 'moment', 'ember-cp-validations', 'bottomline-execution/models/base', 'bottomline-execution/utils/math-util'], function (exports, _emberData, _moment, _emberCpValidations, _base, _mathUtil) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;


  var Validations = (0, _emberCpValidations.buildValidations)({
    hasTicketNumberOnConfirmedQty: {
      description: 'Ticket number',
      validators: [(0, _emberCpValidations.validator)(function (value, options, model) {
        return model.validatesTicketNb();
      }, {
        dependentKeys: ['model.TicketNb', 'model.trip_stop.ExtOrderID'],
        disabled: Ember.computed.equal('model.plannedQty', 0)
      })]
    },
    hasEqualConfirmedAndTotalQuantity: {
      validators: [(0, _emberCpValidations.validator)(function (value, options, model) {
        return model.hasValidVolume();
      }, {
        dependentKeys: ['model.totalCompartmentQty', 'model.confirmedQty']
      })]
    }
  });

  exports.default = _base.default.extend(Validations, {
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),
    truckSettings: Ember.computed.alias('appConfiguration.truckSettings'),
    currentTruckInventory: Ember.inject.service(),

    totalCompartmentQty: 0,
    disableStorageScanButton: true,

    TripStopID: attr('string'),
    plannedQty: attr('number'),
    confirmedQty: attr('number', { defaultValue: 0 }),
    confirmed15Qty: attr('number', { defaultValue: 0 }),
    TicketNb: attr('string'),
    MeterDeliveryInfo: attr('string'),
    meterQty: attr('number'),
    meter15Qty: attr('number'),
    MaterialID: attr('string'),
    MaterialBuyerCode: attr('string'),
    StorageID: attr('number'),
    confirmedWeight: attr('number'),
    meterWeight: attr('number'),
    Density: attr('number'),
    Temperature: attr('string'),
    TripID: attr('number'),
    startInventoryQty: attr('number', { defaultValue: 0 }),
    endInventoryQty: attr('number', { defaultValue: 0 }),
    DropCount: attr('number'),
    TankFilledYN: attr('boolean', { defaultValue: false }),
    orderQty: attr('number'),

    tssMarker: null,

    processDeliveryFlag: true,

    processDelivery: Ember.computed('processDeliveryFlag', 'plannedQty', 'trip_stop.hasSeningMeter', function () {
      return this.get('processDeliveryFlag') === true && this.get('plannedQty') > 0 && this.get('trip_stop.hasSeningMeter');
    }),

    trip: belongsTo('trip', { async: false }),
    trip_stop: belongsTo('trip-stop', { async: false }),
    storage: belongsTo('storage', { async: false }),

    deliveryTolerance: Ember.computed.alias('material.material_delivery_tolerance'),
    derive_delivery_tank_15qty: Ember.computed.alias('truckSettings.derive_delivery_tank_15qty'),
    confirmedQtyChanged: Ember.observer('confirmedQty', function () {
      this.set('disableStorageScanButton', !this.get('confirmedQty'));
    }),
    storageSequenceNb: Ember.computed.alias('storage.SequenceNb'),
    unitInKgChanged: Ember.observer('trip_stop.unitInKg', function () {
      if (Ember.isEmpty(this.get('material'))) {
        return;
      }

      if (Ember.isEmpty(this.get('Density')) && this.get('isLoaded')) {
        this.set('Density', this.get('material').get('MaterialDensity'));
      }

      if (this.get('trip_stop') && this.get('trip_stop').get('unitInKg')) {
        this.set('confirmedWeight', Math.round(this.get('confirmedQty') * this.get('Density')) || 0);
      }
    }),
    changedConfirmedWeight: Ember.observer('confirmedWeight', function () {
      if (this.get('trip_stop') && this.get('trip_stop').get('unitInKg')) {
        this.set('confirmedQty', Math.round(this.get('confirmedWeight') / this.get('Density')));
      }
    }),
    changedConfirmedQty: Ember.observer('confirmedQty', function () {
      if (this.get('trip_stop') && !this.get('trip_stop').get('unitInKg')) {
        this.set('confirmedWeight', Math.round(this.get('confirmedQty') * this.get('Density')));
      }
    }),
    dropCountObserver: Ember.observer('DropCount', function () {
      if (this.get('DropCount') >= 1 && Ember.isEmpty(this.get('confirmedQty'))) {
        this.set('confirmedQty', 0);
      }
    }),
    material: Ember.computed('MaterialID', 'material.@each.isLoaded', function () {
      if (this.get('MaterialID')) {
        return this.store.peekRecord('material', this.get('MaterialID'));
      }
    }),
    shouldShowATGWarning: Ember.computed('appConfiguration.truckSettings.show_atg_inventory_warnings', 'storage.InventoryMethod', function () {
      var inventoryMethod = this.get('storage.InventoryMethod');

      return this.get('appConfiguration.truckSettings.show_atg_inventory_warnings') && (inventoryMethod === 2 || inventoryMethod === 3);
    }),
    inventoryDataIsRecent: Ember.computed('trip_stop.ConfirmedArrivalDT', 'trip_stop.PlannedArrivalDT', 'storage.recent_inventory.InventoryDT', function () {
      var inventoryDate = this.get('storage.recent_inventory.InventoryDT');

      if (inventoryDate) {
        var targetDate = this.get('trip_stop.ConfirmedArrivalDT') || this.get('trip_stop.PlannedArrivalDT');

        return (0, _moment.default)(inventoryDate).add(4, 'hours').isAfter(targetDate);
      }
    }),
    ATGWarningClass: Ember.computed('shouldShowATGWarning', 'storage.availableQty', 'plannedQty', 'inventoryDataIsRecent', function () {
      if (this.get('shouldShowATGWarning')) {
        var plannedQty = this.get('plannedQty') || 0;
        var canLoadToTank = this.get('storage.availableQty') - plannedQty > 0;

        return canLoadToTank && this.get('inventoryDataIsRecent') ? 'tank-inventory-green' : 'tank-inventory-red';
      }
    }),
    availableQty: Ember.computed('startInventoryQty', function () {
      return (0, _mathUtil.round)(this.get('storage') && this.get('storage').get('usableStorageQty') - this.get('startInventoryQty') || 0, 0);
    }),
    truckId: Ember.computed('TripStopID', 'StorageID', 'appConfiguration.truckSettings.compartments', function () {
      var _this = this;

      var compartmentsEnabled = this.get('appConfiguration.truckSettings.compartments');
      var tripStopTruckStorages = this.get('store').peekAll('trip-stop-truck-storage').filter(function (tsts) {
        return tsts.get('TripStopID') === _this.get('TripStopID') && tsts.get('StorageID') === _this.get('StorageID');
      });
      var tripStopTruckStorage = compartmentsEnabled ? tripStopTruckStorages.find(function (tsts) {
        return tsts.get('TruckStorageID');
      }) : tripStopTruckStorages.find(function (tsts) {
        return tsts.get('TruckStorageID') === null;
      });

      if (tripStopTruckStorage) {
        return tripStopTruckStorage.get('TruckID');
      }

      return this.get('trip.shift.TrailerID') || this.get('trip.shift.TruckID');
    }),
    meterType: Ember.computed('truckId', function () {
      var truckId = this.get('truckId');

      if (truckId) {
        var meterTarget = this.get('store').peekRecord('truck', truckId) || this.get('store').peekAll('trailer').compact().find(function (trailer) {
          return trailer.get('TruckID') === truckId;
        });

        return meterTarget.get('MeterTypeID');
      }
    }),
    startInventoryQtyPercentage: Ember.computed('startInventoryQty', {
      get: function get() {
        if (!this.get('storage')) {
          return 0;
        }

        var val = this.get('startInventoryQty') / this.get('storage').get('totalStorageQty') * 100;

        return (0, _mathUtil.round)(val, 0);
      },
      set: function set(key, val) {
        var total = this.get('storage').get('totalStorageQty');

        var result = Number(val) / 100 * total;
        this.set('startInventoryQty', (0, _mathUtil.round)(result, 0));

        return val;
      }
    }),
    totalCurrentQty: Ember.computed(function () {
      return this.startInventory() ? this.startInventory().get('confirmedQty') : 0;
    }),
    totalQty: Ember.computed('confirmedQty', function () {
      var confirmedQty = Number(this.get('confirmedQty'));

      return isNaN(confirmedQty) ? this.get('totalCurrentQty') : this.get('totalCurrentQty') + confirmedQty;
    }),
    currentInventory: Ember.computed(function () {
      return this.get('confirmedQty');
    }),
    currentCompartmentInventory: Ember.computed('trip_stop.shift.truck_inventories.@each.currentQty', function () {
      var _this2 = this;

      var truckInventories = this.get('currentTruckInventory').byCompartment().filter(function (truckInventory) {
        return truckInventory.get('material_id') === _this2.get('MaterialID');
      });

      if (truckInventories.length === 0) {
        return { totalcurrentQty: 0 };
      }

      return this.groupBy(truckInventories, 'MaterialID', ['currentQty'])[0];
    }),
    hasQty: Ember.computed.or('confirmedQty', 'plannedQty'),
    plannedQtyInWeight: Ember.computed('Density', 'plannedQty', function () {
      return Math.round(this.get('Density') * this.get('plannedQty'));
    }),
    currentQtyInWeight: Ember.computed('Density', 'totalCurrentQty', function () {
      return Math.round(this.get('Density') * this.get('totalCurrentQty'));
    }),
    totalQtyInWeight: Ember.computed('Density', 'totalQty', function () {
      return Math.round(this.get('Density') * this.get('totalQty'));
    }),
    isPlannedQtyExceedsAvailableQty: Ember.computed('plannedQty', 'storage.availableQty', function () {
      return this.get('plannedQty') > this.get('storage.availableQty');
    }),

    tripStopTruckStorages: function tripStopTruckStorages() {
      return this.get('trip_stop.trip_stop_truck_storages').filterBy('StorageID', this.get('StorageID'));
    },
    startInventory: function startInventory() {
      var tripStop = this.get('trip_stop.trip').startInventoryStop();

      return tripStop.get('trip_stop_storages').findBy('MaterialID', this.get('MaterialID'));
    },
    calculateTotalCompartmentQty: function calculateTotalCompartmentQty() {
      var total = this.get('trip_stop').tripStopTruckStorageTotalByStorage(this.get('StorageID'));

      this.set('totalCompartmentQty', total);
    },
    setTotal15Qty: function setTotal15Qty() {
      if (!this.get('truckSettings.compartments')) return;

      var sum = function sum(_sum, current) {
        return _sum + Number(current);
      }; // Define reducer function
      var total = null;

      if (this.get('trip_stop.isLoad')) {
        total = this.tstsLoaded15Qtys().reduce(sum, 0);
      }

      if (this.get('trip_stop.isUnload') && this.get('derive_delivery_tank_15qty')) {
        total = (0, _mathUtil.round)(this.tstsUnloaded15Qtys().reduce(sum, 0));
      }

      if (Ember.isPresent(total)) this.set('confirmed15Qty', total);
    },
    tstsLoaded15Qtys: function tstsLoaded15Qtys() {
      return this.tripStopTruckStorages().filterBy('TruckStorageID').mapBy('confirmed15Qty');
    },
    tstsUnloaded15Qtys: function tstsUnloaded15Qtys() {
      var loadStopCompartments = this.get('trip.loadStop.trip_stop_truck_storages');
      var compartments = this.tripStopTruckStorages().filterBy('TruckStorageID');

      return compartments.map(function (compartment) {
        var loadStopCompartment = loadStopCompartments.findBy('TruckStorageID', compartment.get('TruckStorageID'));
        return compartment.deriveConfirmed15Qty(loadStopCompartment);
      });
    },
    setTemperatureAverage: function setTemperatureAverage() {
      var storages = this.tripStopTruckStorages().filter(function (storage) {
        return Number(storage.get('Temperature')) !== 0;
      });

      if (storages.length === 0) {
        return;
      }

      var totalTemperature = storages.reduce(function (currentValue, storage) {
        var _storage$getPropertie = storage.getProperties(['Temperature', 'confirmed15Qty']),
            temperature = _storage$getPropertie.Temperature,
            volume = _storage$getPropertie.confirmed15Qty;

        return Number(currentValue) + Number(temperature * volume);
      }, null);

      var temperatureAverage = (totalTemperature / this.get('confirmed15Qty')).toFixed(2);
      if (typeof temperatureAverage === 'number' && isFinite(temperatureAverage)) {
        this.set('Temperature', temperatureAverage);
      }
    },
    hasValidVolume: function hasValidVolume() {
      var group = this.get('trip_stop.tstsByMaterial').findBy('group', this.get('MaterialID'));

      if (Ember.isEmpty(group) || group.items.length === 0) {
        return true;
      } else if (this.get('plannedQty') === 0 && Ember.isEmpty(this.get('confirmedQty'))) {
        return true;
      }

      return this.get('confirmedQty') === this.get('totalCompartmentQty');
    },
    getLoad: function getLoad() {
      var _this3 = this;

      this.get('store').query('load', {
        StorageID: this.get('StorageID'), TicketNb: this.get('trip_stop.ExtOrderID')
      }).then(function (result) {
        var results = result.toArray();

        if (results.length) {
          var _results$reduce = results.reduce(function (loadDetails, loadItem) {
            var compartment = _this3.get('trip_stop.trip_stop_truck_storages').find(function (compartment) {
              return compartment.get('TruckStorageID') === Number(loadItem.get('CompartmentCode'));
            });

            var compartmentData = {
              confirmedQty: loadItem.get('ActualQty'),
              confirmed15Qty: loadItem.get('Deg15Qty'),
              Temperature: loadItem.get('Temperature'),
              Density: loadItem.get('Density'),
              StorageID: _this3.get('StorageID'),
              MaterialID: _this3.get('MaterialID')
            };

            compartment ? compartment.setProperties(compartmentData) : null;

            return Object.assign({}, loadDetails, {
              actual: loadDetails.actual + loadItem.get('ActualQty'),
              deg15: loadDetails.deg15 + loadItem.get('Deg15Qty'),
              avgTmp: loadDetails.avgTmp + loadItem.get('Temperature') * loadItem.get('ActualQty'),
              avgDensity: loadDetails.avgDensity + loadItem.get('Density') * loadItem.get('ActualQty')
            });
          }, { actual: 0, deg15: 0, avgTmp: 0, avgDensity: 0 }),
              actual = _results$reduce.actual,
              deg15 = _results$reduce.deg15,
              avgTmp = _results$reduce.avgTmp,
              avgDensity = _results$reduce.avgDensity;

          _this3.set('Temperature', Math.round(avgTmp / actual * 100) / 100);
          _this3.set('Density', Math.round(avgDensity / actual * 10000) / 10000);
          _this3.set('confirmedQty', actual);
          _this3.set('confirmed15Qty', deg15);
          _this3.calculateTotalCompartmentQty();
        }
      });
    },
    validatesTicketNb: function validatesTicketNb() {
      if (this.get('trip_stop').validatesExtOrderID()) {
        return true;
      }

      var ticketNbIsInvalid = Ember.isPresent(this.get('confirmedQty')) && Ember.isBlank(this.get('TicketNb'));

      return !ticketNbIsInvalid;
    },
    syncToConfirmed: function syncToConfirmed() {
      this.set('confirmedQty', this.get('totalCompartmentQty'));
    },
    resetProcessDeliveryFlag: function resetProcessDeliveryFlag() {
      this.set('processDeliveryFlag', true);
    },
    updateConfirmedQty: function updateConfirmedQty() {
      this.calculateTotalCompartmentQty();
      this.syncToConfirmed();
      if (this.get('truckSettings.temp_density_ltr15_load_enabled')) {
        this.setTotal15Qty();
        this.setTemperatureAverage();
      }
    }
  });
});
define('bottomline-execution/app', ['exports', 'ember-data', 'bottomline-execution/resolver', 'ember-load-initializers', 'bottomline-execution/config/environment', '@sentry/browser', '@sentry/integrations', 'bottomline-execution/utils/guid-util'], function (exports, _emberData, _resolver, _emberLoadInitializers, _environment, _browser, _integrations, _guidUtil) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  if (_environment.default.environment !== 'development') {
    _browser.init({
      dsn: _environment.default.SENTRY_DSN,
      integrations: [new _integrations.Ember()],
      release: '00.00.00', // This is replaced in the CircleCI build process
      beforeSend: function beforeSend(event, hint) {
        var breadcrumbs = event.breadcrumbs || [];
        var serverErrorPresent = breadcrumbs.get('lastObject.data.status_code') === 500;
        var requestAborted = hint.originalException.message === 'The adapter operation was aborted';

        var rejectError = !window.cordova || serverErrorPresent || requestAborted;

        if (rejectError) {
          event.exception.values.forEach(function (error) {
            var errorMessage = error.type + ': ' + error.value;
            var stackTrace = error.stacktrace.frames.map(function (f) {
              return '    at ' + f.function + ' (' + f.filename + ':' + f.lineno + ')';
            }).join("\r\n");

            console.log('%cError report for Sentry intercepted', 'color:red', errorMessage);
            console.log('%c' + stackTrace, 'color:red');
          });

          _browser.configureScope(function (scope) {
            return scope.clearBreadcrumbs();
          });
          return null;
        }
        return event;
      }
    });
  }

  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  _emberData.default.Snapshot.prototype.adapterAction = function () {
    return (this.adapterOptions || {}).action;
  };

  _emberData.default.Model.reopen({
    rollbackAttribute: function rollbackAttribute(attributeName) {
      var originalValue = this.changedAttributes(attributeName)[0];

      this.set(attributeName, originalValue);
    },
    groupBy: function groupBy(list, group, totals) {
      var groupedResult = Ember.A([]);
      var groups = Ember.A(Ember.A(list).getEach(group)).uniq();

      groups.forEach(function (item) {
        var currentGroup = new Ember.Object();
        var itemsForGroup = list.filterBy(group, item);

        currentGroup.set('items', itemsForGroup);
        currentGroup.set('group', item);

        totals.forEach(function (total) {
          var totalVal = itemsForGroup.reduce(function (prev, item) {
            if (isNaN(prev)) {
              prev = 0;
            }

            return parseInt(prev, 10) + parseInt(item.get(total) || 0, 10);
          }, 0);

          currentGroup.set('total' + total, totalVal);
        });

        if (itemsForGroup.length > 0) {
          groupedResult.push(currentGroup);
        }
      });

      return groupedResult;
    },
    createGuid: function createGuid() {
      return (0, _guidUtil.guidUtil)();
    }
  });

  exports.default = App;
});
define('bottomline-execution/components/material-on-truck', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var htmlSafe = Ember.String.htmlSafe;
  exports.default = Ember.Component.extend({
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),
    truckSettings: Ember.computed.alias('appConfiguration.truckSettings'),
    currentTruckInventory: Ember.inject.service(),

    materialStyle: Ember.computed('inventory.material', 'inventory', function () {
      return new htmlSafe('color: ' + this.get('inventory.material.foreground_color') + '; ' + ('background-color: ' + this.get('inventory.material.background_color') + ';'));
    }),

    materialInventoriesWithTripStopNeed: Ember.computed('selectedTripStop', function () {
      var currentTruck = this.get('appState.currentTruck');
      var selectedTripStop = this.get('selectedTripStop');

      if (Ember.isBlank(selectedTripStop)) return [];

      var materialInventoriesWithNeed = this.get('currentTruckInventory').forVehicle(currentTruck).map(function (inventory) {
        inventory.set('neededQty', selectedTripStop.totalPlannedQty(inventory.get('material_id')));
        return inventory;
      });

      return materialInventoriesWithNeed.filter(function (inventory) {
        return inventory.get('currentQty') || inventory.get('neededQty');
      }).sortBy('material.MaterialNameShort', 'material_id');
    })
  });
});
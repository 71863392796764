define('bottomline-execution/services/compartment-based/current-truck-inventory', ['exports', 'bottomline-execution/services/current-truck-inventory'], function (exports, _currentTruckInventory) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _currentTruckInventory.default.extend({
    store: Ember.inject.service(),
    quantityForMaterial: function quantityForMaterial(materialId) {
      return this.compartmentInventories().filterBy('material_id', materialId).reduce(function (qty, inventory) {
        return qty + inventory.get('currentQty');
      }, 0);
    },
    forVehicle: function forVehicle(vehicleIds) {
      return this.compartmentInventories(vehicleIds);
    },
    _initialQuantityForMaterialInventory: function _initialQuantityForMaterialInventory(materialId) {
      return this.quantityForMaterial(materialId);
    },
    updateMaterialsIds: function updateMaterialsIds(loadStopTruckStorages) {
      var _this = this;

      var loadedTsts = loadStopTruckStorages.filterBy('confirmedQty');

      this.compartmentInventories().forEach(function (inventory) {
        var tstsForInventory = loadedTsts.findBy('TruckStorageID', inventory.get('TruckStorageID'));
        if (Ember.isEmpty(tstsForInventory)) return;

        var loadedMaterialId = tstsForInventory.get('MaterialID');
        console.log('%cUpdate material for compartment ' + inventory.get('TruckStorageID') + ' (loaded: ' + loadStopTruckStorages.findBy('TruckStorageID', inventory.get('TruckStorageID')).get('confirmedQty') + ') from ' + inventory.get('material.MaterialNameShort') + ' to ' + _this.get('store').peekRecord('material', loadedMaterialId).get('MaterialNameShort'), "color:blue");
        inventory.set('material_id', loadedMaterialId);
      });
    },


    withTruckStorageId: Ember.computed.alias('forCompartment'),

    forCompartment: function forCompartment(truckStorageId) {
      return this.compartmentInventories().findBy('truck_storage_id', truckStorageId);
    },


    byCompartment: Ember.computed.alias('compartmentInventories'),

    compartmentInventories: function compartmentInventories() {
      var vehicleIds = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [this.get('truckId'), this.get('trailerId')];

      return this.allInventoriesForVehicle(vehicleIds).filter(function (inventory) {
        return Number(inventory.get('truck_storage_id')) > 0;
      });
    }
  });
});
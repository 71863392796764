define('bottomline-execution/mixins/raw-truck-settings', ['exports', 'bottomline-execution/mixins/truck-settings-id', 'bottomline-execution/mixins/raw-app-state'], function (exports, _truckSettingsId, _rawAppState) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_truckSettingsId.default, _rawAppState.default, {
    useCompartments: Ember.computed(function () {
      return this._truckSettings().compartments === true;
    }).volatile(),

    _truckSettings: function _truckSettings() {
      if (localStorage['truck-setting'] && localStorage.state) {
        return JSON.parse(localStorage['truck-setting'])['truck-setting'].records[this.truckSettingsId(this._appState().currentTruck)];
      } else {
        return new Ember.Object();
      }
    }
  });
});